import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';  //aqui adicionado
import { Game } from '../models/Game';              //aqui adicionado
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GamesService {

					
  //API_URI = 'http://localhost:3000/api';          
  API_URI = 'http://nodejs.kinghost.net:21139/api';   //aqui adicionado

  constructor(private http: HttpClient) { }       //aqui adicionado

  getGames() {
			 
    return this.http.get(`${this.API_URI}/games`); //API_URI funcionou com esta aspas: `
  }

  getGame(id: string) {
    return this.http.get(`${this.API_URI}/games/${id}`);
  }

  deleteGame(id: string) {
    return this.http.delete(`${this.API_URI}/games/${id}`);
  }

  saveGame(game: Game) {
    return this.http.post(`${this.API_URI}/games`, game);
  }

  updateGame(id: string|number, updatedGame: Game): Observable<Game> {
    return this.http.put(`${this.API_URI}/games/${id}`, updatedGame);
  }

}
