import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GameListComponent } from './components/game-list/game-list.component'; //aqui componete importado
import { GameFormComponent } from './components/game-form/game-form.component'; //aqui componete importado

//---Aqui adicionado---
const routes: Routes = [
  {
    path: '',
    redirectTo: '/games',                 
    pathMatch: 'full'
  },
  {
    path: 'games',                        //aqui link do menu games
    component: GameListComponent          //componente que rederiza o html
  },
  {
    path: 'games/add',                   //aqui link do menu games add e seu componente abaixo  
    component: GameFormComponent         //componente que rederiza o html
  },
  {
    path: 'games/edit/:id',              //aqui link do botao editar e seu componente abaixo  
    component: GameFormComponent         //componente que rederiza o html
  }

];
//---
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
