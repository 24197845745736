import { Component, OnInit, HostBinding } from '@angular/core';
import { Game } from '../../models/Game';
import { GamesService } from '../../services/games.service';
import { Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-game-form',
  templateUrl: './game-form.component.html',
  styleUrls: ['./game-form.component.css']
})
export class GameFormComponent implements OnInit {

  @HostBinding('class') classes = 'row';               //aqui adicionado deixa na mesma linha

  game: Game = {
    id: 0,
    title: '',
    description: '',
    image: '',
    created_at: new Date()
  };

  edit: boolean = false;

  constructor(private gamesService: GamesService, private router: Router, private activedRoute: ActivatedRoute) { }

  ngOnInit() {
    const params = this.activedRoute.snapshot.params;   //aqui pega somente o link ativo e nao colocado no browser
    if(params.id) {
      this.gamesService.getGame(params.id)
      .subscribe(
        res => {
          console.log(res);
          this.game = res;
          this.edit = true;
        },
        err => console.error(err)
      )
    }
  }

  saveNewGame() {
    //console.log(this.game);       //aqui escreve no console os campos do formulario
    delete this.game.id;            //nao precisa gravar
    delete this.game.created_at;    //nao precisa gravar

    this.gamesService.saveGame(this.game)
    .subscribe(
      res => {
        console.log(res);
        this.router.navigate(['/games']);   //volta pra listagem
      },
      err => console.error(err)
    )
  }

  updateGame() {
    delete this.game.created_at;
    this.gamesService.updateGame(this.game.id, this.game)
    .subscribe(
      res => {
        console.log(res)
        this.router.navigate(['/games']);
      }
    )
  }
}
